import { api } from "@/api/api";
import type {
  DamageType,
  IncidentPreset,
  IncidentPresetShortcut,
  IncidentType,
  Industry,
  StructureType,
} from "@/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

type Response = {
  structureTypes: StructureType[];
  incidentTypes: IncidentType[];
  damageTypes: DamageType[];
  industries: Industry[];
  incidentPresets: IncidentPreset[];
  incidentPresetShortcuts: IncidentPresetShortcut[];
};

export function useConfiguration(options: UseQueryOptions<Response> | void) {
  return useQuery({
    queryKey: ["config"],
    queryFn: async () => {
      const response = await api.get<Response>(`config`);
      return response.data;
    },

    gcTime: Infinity,
    staleTime: Infinity,
    retry: 3,
    retryOnMount: true,
    retryDelay: 1000,

    ...options,
  });
}
