"use client";

import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { DateModal } from "@/components/DateModal";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import { dayjs } from "@/util/dayjs";

type Props = {
  onSubmit?: (data: {
    from: dayjs.Dayjs;
    to: dayjs.Dayjs;
  }) => Promise<unknown> | void;
};

export function DateModalButton({ onSubmit }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  return (
    <>
      <Button
        color="secondary"
        onClick={openModal}
        sx={{
          width: "48px",
          height: "48px",
        }}
      >
        <CalendarIcon
          sx={{
            color: "#78909C",
          }}
        />
      </Button>

      {modalOpen ? (
        <DateModal onClose={closeModal} onSubmit={onSubmit} />
      ) : null}
    </>
  );
}
