export type StorageKey = "token" | "incidentFilter";

export const storage = {
  async get(key: StorageKey) {
    return localStorage.getItem(key);
  },

  async set(key: StorageKey, value: string) {
    return localStorage.setItem(key, value);
  },

  async remove(key: StorageKey) {
    return localStorage.removeItem(key);
  },
};
