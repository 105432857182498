"use client";

import { useBusiness } from "@/hooks/useBusiness";
import { useIncidentFilter } from "@/hooks/useIncidentFilter";
import { useSelf } from "@/hooks/useSelf";
import { useUpdateIncidentFilter } from "@/hooks/useUpdateIncidentFilter";
import { dayjs } from "@/util/dayjs";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, Stack } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { useCallback, useMemo } from "react";
import { FormProvider, useController, useForm } from "react-hook-form";

type Props = {
  onClose: () => void;

  onSubmit?: (data: {
    from: dayjs.Dayjs;
    to: dayjs.Dayjs;
  }) => Promise<unknown> | void;
};

const style = {
  "& .MuiDialogActions-root": {
    display: "none",
  },

  "& .MuiDatePickerToolbar-title": {
    display: "none",
  },

  "& .MuiPickersToolbar-content": {
    display: "none",
  },

  "& .MuiPickersToolbar-root": {
    padding: "0 24px",
  },
};

const buttons: [string, dayjs.Dayjs][] = [
  ["Today", dayjs()],
  ["This Week", dayjs().startOf("week")],
  ["This Month", dayjs().startOf("month")],
  ["Last 6 Months", dayjs().subtract(6, "month")],
];

export function DateModal({ onClose, ...props }: Props) {
  const { data: incidentFilter } = useIncidentFilter();
  const { data: self } = useSelf();

  const form = useForm({
    defaultValues: {
      from: dayjs(incidentFilter?.from || undefined),
      to: dayjs(incidentFilter?.to),
    },
  });

  const updateIncidentFilter = useUpdateIncidentFilter();

  const from = form.watch("from");

  const fromController = useController({
    control: form.control,
    name: "from",
  });

  const toController = useController({
    control: form.control,
    name: "to",
  });

  const activeButtonIndex = useMemo(
    () => buttons.findIndex(([, date]) => date.isSame(from, "day")),
    [from],
  );

  const minimumDate = useMemo(
    () =>
      self?.isStaff
        ? undefined
        : dayjs.utc(self?.Business?.earliestIncidentDateAccess),
    [self?.Business?.createdAt],
  );

  const onSubmit = useMemo(
    () =>
      form.handleSubmit(async (data) => {
        if (props.onSubmit) {
          await props.onSubmit(data);
          onClose();
        } else {
          await updateIncidentFilter.mutateAsync({
            from: data.from.toISOString(),
            to: data.to.toISOString(),
          });

          onClose();
        }

        // await updateIncidentFilter.mutateAsync(
        //   {
        //     from: data.from.toISOString(),
        //     to: data.to.toISOString(),
        //   },
        //   {
        //     onSuccess: onClose,
        //   }
        // );
      }),
    [props.onSubmit],
  );

  if (!self) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <Dialog
        open
        fullWidth
        onClose={onClose}
        maxWidth="xl"
        component="form"
        onSubmit={onSubmit}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Stack
          sx={{
            flex: 1,
            backgroundColor: "white",
            margin: "auto",
            padding: "2rem",
            borderRadius: "12px",
            width: "100%",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
            }}
          >
            <StaticDatePicker
              disableFuture
              minDate={minimumDate}
              localeText={{
                toolbarTitle: "",
              }}
              sx={style}
              {...fromController.field}
            />

            <StaticDatePicker
              disableFuture
              minDate={from}
              localeText={{
                toolbarTitle: "",
              }}
              sx={style}
              {...toController.field}
            />

            <Stack
              sx={{
                gap: 1,
              }}
            >
              {buttons.map(([label, date], index) => (
                <Button
                  key={index}
                  color={activeButtonIndex === index ? "primary" : "secondary"}
                  sx={{
                    textAlign: "left !important",
                    fontWeight: "400 !important",
                    justifyContent: "flex-start",
                    paddingLeft: "1rem !important",
                    whiteSpace: "nowrap",
                    height: "40px !important",
                  }}
                  onClick={() => {
                    fromController.field.onChange(date);
                    toController.field.onChange(dayjs());
                  }}
                >
                  {label}
                </Button>
              ))}

              <Button
                color={activeButtonIndex === -1 ? "primary" : "secondary"}
                sx={{
                  pointerEvents: "none",
                  textAlign: "left !important",
                  fontWeight: "400 !important",
                  justifyContent: "flex-start",
                  paddingLeft: "1rem !important",
                  whiteSpace: "nowrap",
                  height: "40px !important",
                }}
              >
                Custom Range
              </Button>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 2,
              flex: 1,
            }}
          >
            <Button
              onClick={onClose}
              // @ts-ignore
              color="tertiary"
              sx={{
                minWidth: "180px",
                height: "40px !important",
                fontWeight: "400 !important",
              }}
            >
              Cancel
            </Button>

            <LoadingButton
              type="submit"
              disabled={
                form.formState.isSubmitting || form.formState.isSubmitted
              }
              sx={{
                minWidth: "180px",
                height: "40px !important",
                fontWeight: "400 !important",
              }}
            >
              {form.formState.isSubmitting || form.formState.isSubmitted
                ? null
                : "Apply"}
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
    </FormProvider>
  );
}
