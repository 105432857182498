import { useConfiguration } from "@/hooks/useConfiguration";
import { IncidentFilter } from "@/types";
import { storage } from "@/util/storage";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSearchParams } from "next/navigation";

type Props = Omit<
  UseQueryOptions<IncidentFilter>,
  "queryKey" | "queryFn"
> | void;

export function useIncidentFilter(props: Props) {
  const { data: configuration } = useConfiguration();
  const searchParams = useSearchParams();

  return useQuery<IncidentFilter>({
    enabled: !!configuration,
    queryKey: ["filters", "incidents"],

    queryFn: async () => {
      const storedIncidentFilter = await storage.get("incidentFilter");

      let incidentFilter: IncidentFilter = {
        category: "all",
        keywords: searchParams.get("keywords") || "",
        location: searchParams.get("location") || "",
        incidentStatus: "active",
        limit: 5,
        from: dayjs().subtract(12, "hours").toISOString(),
        to: dayjs().endOf("day").toISOString(),
        radius: 30,
        presets: [],
        active: true,
        propertyAddress: true,
        legalDetails: false,
        stateIds: [],
        countyInclusions: [],
        countyExclusions: [],
        cityInclusions: [],
        cityExclusions: [],
        allCountyStateIds: [],
      };

      if (storedIncidentFilter) {
        incidentFilter = {
          ...incidentFilter,

          /// @ts-ignore
          ...JSON.parse(storedIncidentFilter),
        };
      }

      return incidentFilter;
    },

    ...props,
  });
}
