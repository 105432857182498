import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IncidentFilter } from "@/types";

export function useUpdateIncidentFilter() {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, Partial<IncidentFilter>>({
    // @ts-ignore
    mutationFn: (filter) => {
      queryClient.setQueryData<IncidentFilter>(
        ["filters", "incidents"],
        (storedFilter) => {
          if (!storedFilter) {
            return undefined;
          }

          // console.log("storedFilter", storedFilter);
          // console.log("filter", filter);

          return {
            ...storedFilter,
            ...filter,
          };
        },
      );
    },
  });
}
