"use client";
import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";

import { callTypes } from "@/util/callTypes";
import { useController, useFormContext } from "react-hook-form";
import { PointerEvent, ReactNode, useCallback } from "react";
import { useConfiguration } from "@/hooks/useConfiguration";
import { useBusinessPresetSubscriptions } from "@/hooks/useBusinessPresetSubscriptions";

type Props = SelectProps & {
  name: string;
  handleAll?: () => void;
  allSelected?: Boolean;
  setAllSelected?: (value: Boolean) => void;
  showPreAlerts?: boolean;
  hideInactive?: boolean;
  emptyPresetLabel: string;

  // Used to restrict the selectable presets to that of the business'
  businessId?: string;
};

export function IncidentPresetSelect({
  name,
  label,
  multiple,
  businessId,
  handleAll,
  allSelected,
  setAllSelected,
  showPreAlerts = false,
  hideInactive = true,
  emptyPresetLabel,
  ...props
}: Props) {
  multiple ??= true;

  const form = useFormContext();
  const { data: configuration } = useConfiguration();

  const { field } = useController({
    control: form.control,
    name,
  });

  let value = multiple ? (field.value as string[]) : (field.value as string);

  const renderValue = useCallback(() => {
    if (typeof value === "string") {
      return value;
    }

    // if (value.length > 1 && value.length <= 3) {
    //   const otherCount = value.length - 5;
    //   return `${value.slice(0, 5).join(", ")}...${otherCount} other${
    //     otherCount > 1 ? "s" : ""
    //   }`;
    // }

    if (value.length > 0 && value.length <= 2) {
      return `${value.slice(0, 5).join(", ")}`;
    }

    if (value.length === 0) {
      return emptyPresetLabel;
    }

    return `${value.length} Call Types Selected`;

    // return value.join(", ");
  }, [value]);

  const onChange = useCallback(
    (event: SelectChangeEvent<string | string[]>, child: ReactNode) => {
      field.onChange(event);

      props.onChange?.(event, child);
    },
    [props.onChange],
  );

  const { data: businessPresetSubscriptions } = useBusinessPresetSubscriptions(
    {
      businessId: businessId as string,
    },
    {
      enabled: !!businessId,
    },
  );

  let incidentPresets;

  incidentPresets =
    businessPresetSubscriptions?.map((subscription) => subscription.Preset) ??
    configuration?.incidentPresets ??
    [];

  if (hideInactive) {
    incidentPresets = incidentPresets.filter((el) => el.active);
  }

  let preAlertArr = incidentPresets.filter((preset) =>
    preset.label.toLowerCase().replace(/[\s-]/g, "").includes("prealert"),
  );

  let presetArr = incidentPresets.filter(
    (preset) =>
      !preset.label.toLowerCase().replace(/[\s-]/g, "").includes("prealert"),
  );

  incidentPresets = showPreAlerts ? preAlertArr.concat(presetArr) : presetArr;

  return (
    <FormControl
      sx={{
        flex: 1,
      }}
    >
      {label ? (
        <InputLabel id="incident-preset-label">{label}</InputLabel>
      ) : null}

      <Select
        disabled={props.disabled}
        multiple={multiple}
        labelId="incident-preset-label"
        id="incident-preset-select"
        value={value}
        renderValue={renderValue}
        onChange={onChange}
        displayEmpty
        // @ts-ignore
        defaultValue={props.defaultValue}
        input={label ? <OutlinedInput label={label} /> : undefined}
        sx={{
          flex: 1,
          minWidth: "100%",
          maxWidth: 520,
        }}
        MenuProps={{
          disableScrollLock: true,

          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },

          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },

          sx: {
            // About 10 items
            maxHeight: multiple ? "750px" : "570px",
          },
        }}
      >
        <MenuItem value="" hidden />
        {!!handleAll && (
          <div>
            <MenuItem key="All" sx={{ borderBottom: "1px solid grey" }}>
              {
                <Checkbox
                  checked={allSelected}
                  onClick={async (e) => {
                    if (allSelected) {
                      field.onChange([]);
                    }
                    await handleAll();

                    // }
                  }}
                />
              }

              <ListItemText primary="All" />
            </MenuItem>
          </div>
        )}

        {incidentPresets.map((incidentPreset) => (
          <MenuItem key={incidentPreset.label} value={incidentPreset.label}>
            {multiple ? (
              <Checkbox checked={value.includes(incidentPreset.label)} />
            ) : null}

            <ListItemText primary={incidentPreset.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
